<template>
  <div id="header" class="sticky top-0 z-20">
    <HeaderRibbon v-if="hasShop" />
    <header class="sticky top-0 z-50 bg-black">
      <div>
        <div
          id="header-bar"
          class="z-50 rounded-t-xl bg-background-extra-light shadow-md md:shadow-[0px_0px_0px_1px_rgba(0,0,0,0.087)]"
          :class="{ 'nav-menu-open': isNavMenuOpen }"
        >
          <div
            id="header-grid"
            class="mx-auto max-w-[1440px] md:gap-8 md:pl-5 md:pr-1 xl:gap-12"
          >
            <HeaderMenuMobileOpenButton v-if="!$viewport.isGreaterThan('md')" />

            <HeaderLogo />

            <HeaderMenu />

            <div
              v-if="hasShop"
              class="header-search flex items-center justify-center"
            >
              <SearchForm @search-focus="hideSpeakToVet = $event" />
            </div>

            <div v-if="hasShop" class="ml-auto flex gap-1">
              <HeaderSwedishMedicalProductsAgency
                v-if="isPharmaEnabled && $i18n.locale === 'sv'"
              />
              <ClientOnly>
                <template #fallback>
                  <!-- this will be rendered on server side -->
                  <div
                    v-if="isAccountsEnabled && hasShop"
                    class="ml-2 flex w-[clamp(80px,95px,140px)] cursor-pointer items-center self-center justify-self-center md:w-[clamp(80px,100px,140px)]"
                  >
                    <StaticPictogram
                      size="lg"
                      name="account"
                      class="mr-1 min-h-[24px] min-w-[24px] md:mr-2"
                    />
                    <span class="text-2xs">
                      {{ $t('account.account_and_prescriptions') }}
                    </span>
                  </div>
                </template>

                <NuxtLink
                  v-if="isAccountsEnabled && hasShop"
                  :to="accountUrl"
                  class="ml-2 flex w-[clamp(80px,95px,140px)] items-center self-center justify-self-center overflow-hidden md:w-[clamp(80px,100px,140px)]"
                >
                  <StaticPictogram
                    size="lg"
                    :name="!isLoggedIn ? 'account' : 'account-filled'"
                    class="mr-1 min-h-[24px] min-w-[24px] md:mr-2"
                  />
                  <span class="text-2xs">
                    <template
                      v-if="profile && profile.firstName && isLoggedIn"
                      >{{ profile.firstName }}</template
                    >
                    <template v-else>{{
                      $t('account.account_and_prescriptions')
                    }}</template>
                  </span>
                </NuxtLink>
              </ClientOnly>

              <button
                v-if="hasShop"
                data-test-id="cart-button"
                :aria-label="$t('cart.show_cart')"
                class="size-13 self-center justify-self-end py-4 pr-5 min-[380px]:pl-4 md:p-4"
                @click="toggleSidebar()"
              >
                <CartPictogram
                  :animate-on-update="true"
                  :cart-amount="itemCount"
                  size-class="w-6 h-6"
                  cart-amount-class="text-white text-xs md:text-base"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <HeaderMenuMobile v-if="!$viewport.isGreaterThan('md')" />
    <transition name="fade">
      <div
        v-show="showSearchSuggestions || isNavMenuOpen"
        class="fixed inset-0 z-20 size-full bg-gray-900/50"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCartStore } from '~/stores/cart';
import { useSearchStore } from '~/stores/search';
import { useSidebarStore } from '~/stores/sidebar';
import { useAuthStore } from '~/stores/auth';
import { useGlobalMenuStore } from '~/stores/headerMenu';

const cartStore = useCartStore();
const searchStore = useSearchStore();

const headerMenu = useGlobalMenuStore();
const { isNavMenuOpen } = storeToRefs(headerMenu);
const sidebarStore = useSidebarStore();
const authStore = useAuthStore();

const { $viewport } = useNuxtApp();
const localePath = useLocalePath();

const { locale } = useI18n();

const hasShop = computed(() => useBaseCountryConfig().hasShop);

const { isAccountsEnabled, prescriptionLocales } =
  useRuntimeConfig().public.featureFlags;
const isPharmaEnabled = prescriptionLocales.includes(locale.value);

const { isLoggedIn, profile } = storeToRefs(authStore);

const hideSpeakToVet = ref(false);

const itemCount = computed(() => cartStore.getOptimisticItemCount);

const showSearchSuggestions = computed(() => searchStore.showSearchSuggestions);

const hasLoggedInBefore = (() => {
  let result = false;

  if (process.client) {
    // We might not always go to a route that can set this value
    // So for safety, we set it in here too
    try {
      if (isLoggedIn.value) {
        localStorage.setItem('hasLoggedInBefore', 'hasLoggedInBefore');

        result = !!localStorage.getItem('hasLoggedInBefore');
      }
    } catch (err) {
      console.info('Localstorage not available');
    }
  }

  return result;
})();

const shopDashboardPath = useDashboardLandingPage();

const authUrl = computed(() => {
  if (hasLoggedInBefore) {
    return authStore.getLoginUrl({
      redirectPath: shopDashboardPath,
    });
  }

  return localePath({ name: 'shop-login' });
});

const accountUrl = computed(() =>
  isLoggedIn.value ? shopDashboardPath : authUrl.value
);
const toggleSidebar = (open?: boolean) => sidebarStore.toggleSidebar(open);

if (isAccountsEnabled && !profile.value?.firstName) {
  authStore.getProfile();
}
</script>

<style type="postcss">
/* When this was written tailwind did not have a sane support for grid area */
@media (max-width: 768px) {
  .header-search {
    grid-area: sub-header;
  }
}
#header-grid {
  display: grid;
  grid-template-columns: max-content max-content auto max-content;
  grid-template-rows: 1fr;
  height: 60px;
}

@media (max-width: 768px) {
  #header-grid {
    grid-area: sub-header;
    display: grid;
    grid-template-columns: 60px 3fr 4fr;
    grid-template-rows: 60px auto;
    gap: 0px 0px;
    height: unset;
    grid-template-areas:
      '. . .'
      'sub-header sub-header sub-header';
  }
}
</style>
