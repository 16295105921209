<!-- eslint-disable vue/no-v-html -->
<template>
  <form
    ref="form"
    v-click-outside="(event) => handleClickOutside(event)"
    role="search"
    class="relative z-20 flex w-full px-3 py-0 pb-3 md:z-60 md:mb-0 md:w-[clamp(100px,100%,291px)] md:max-w-[291px] md:px-0 md:py-2"
    :action="
      localePath({
        name: 'shop-search',
        query: { q: searchQuery, sort },
      })
    "
    @submit.prevent="triggerSearch"
  >
    <div class="flex flex-1 gap-x-2">
      <span class="relative flex-1">
        <span class="absolute left-0 top-0 h-full p-1">
          <button
            class="flex h-full w-9 rounded-xl px-0 focus:outline-none"
            type="button"
            aria-label="search"
            @click="
              showSearchSuggestions
                ? hideSearchSuggestion()
                : $refs.searchInput.focus()
            "
          >
            <DynamicPictogram
              name="search"
              size="lg"
              class="m-auto [&_svg]:p-0.5"
              color="black"
            />
          </button>
        </span>

        <input
          key="serchform"
          ref="searchInput"
          :value="searchQuery"
          name="q"
          class="h-[48px] w-full rounded-xl border border-black/26 bg-black/3 py-3 pl-10 pr-3 text-black/87 placeholder:text-black/54 placeholder:antialiased focus:border-supporting-light-1 focus:outline-none focus:ring-1 focus:ring-supporting-light-1 md:h-[42px] md:py-2"
          data-test-id="search-input-field"
          :class="{ 'pr-20': searchQuery && showSearchSuggestions }"
          type="search"
          autocomplete="off"
          :placeholder="$t('search.placeholder')"
          @input="searchQuery = $event.target.value"
          @keyup="inputKeyup"
          @focus="inputFocus"
        />
        <span
          v-show="searchQuery"
          class="absolute right-0 top-0 flex h-full p-1"
        >
          <button class="my-auto mr-1" type="button" @click="clearSearch">
            <StaticPictogram
              name="clear-search"
              size="lg"
              class="opacity-54 hover:opacity-100"
            />
            <span class="hidden"> {{ $t('search.cancel') }}</span>
          </button>
        </span>
      </span>
      <Button
        v-show="showSearchSuggestions"
        color="transparent"
        class="mx-auto block h-full flex-none !p-0"
        size="sm"
        type="button"
        @click="cancelSearch"
      >
        {{ $t('search.cancel') }}
      </Button>
    </div>
    <div
      v-if="showSearchSuggestions"
      class="absolute -left-3 top-full w-[calc(100%+0.75rem)] overflow-y-auto bg-background-extra-light pb-6"
      style="max-height: calc(calc(var(--vh, 1vh) * 100) - 140px)"
    >
      <div v-if="showCommonSearches">
        <div class="pb-1 pl-10 pt-3 text-md font-normal text-black/87 md:pl-5">
          {{ $t('search.popular_queries') }}
        </div>
        <ul>
          <li v-for="q in commonSearches[locale]" :key="q">
            <NuxtLink
              :to="
                localePath({ name: 'shop-search', query: { q, sort: sort } })
              "
              class="block w-full py-1 pl-14 pr-4 text-black/87 hover:bg-black/3 md:pl-8"
              @click="clickedSearchSuggestion(q)"
            >
              {{ q }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div v-else>
        <div v-if="hasAutoCompleteQueries">
          <ul>
            <li v-for="q in autoCompleteQueries" :key="q">
              <NuxtLink
                :to="
                  localePath({ name: 'shop-search', query: { q, sort: sort } })
                "
                class="block w-full py-1 pl-12 pr-4 font-normal text-black/54 antialiased hover:bg-black/3 md:pl-8"
                @click="clickedSearchSuggestion(q)"
              >
                <div
                  class="truncate"
                  v-html="highlightAutocomplete(q, searchQuery)"
                />
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div v-if="hasAutoCompleteCategories">
          <div
            class="pb-1 pl-10 pt-3 text-md font-normal text-black/87 md:pl-5"
          >
            {{ $t('search.categories') }}
            <span class="text-black/54 antialiased"
              >({{ autoCompleteCategories.length }})</span
            >
          </div>
          <ul>
            <li v-for="category in autoCompleteCategories" :key="category.id">
              <NuxtLink
                :to="localePath(`/${shopName}${category?.path}`)"
                class="block w-full py-1 pl-12 pr-4 font-normal text-black/54 antialiased hover:bg-black/3 md:pl-8"
                @click="hideSearchSuggestion"
              >
                <div
                  class="truncate"
                  v-html="
                    highlightAutocomplete(
                      category.longName && category.longName.length
                        ? category.longName.join(' → ')
                        : category.name,
                      searchQuery
                    )
                  "
                />
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </form>
</template>
<script setup>
import { debounce } from '@/helpers/utils';
import { useSearchStore } from '~/stores/search';

const shopName = computed(
  () => useShopCountryConfig().routes[useLanguage()].shop
);

const { $viewport } = useNuxtApp();
const { locale } = useI18n();
const route = useRoute();
const searchStore = useSearchStore();

const localePath = useLocalePath();
const form = ref(null);
const searchInput = ref(null);
const searchQuery = ref('');
const sort = computed(() => route.query.sort);

watch(
  () => route.query.q,
  (query) => {
    searchQuery.value = query || '';
  },
  { immediate: true }
);

if (
  !searchStore.commonSearches?.[locale.value]?.length &&
  !searchStore.loadingCommonSearches
) {
  await searchStore.getCommonSearches();
}

const commonSearches = computed(() => searchStore.commonSearches);

const showSearchSuggestions = computed(() => searchStore.showSearchSuggestions);
const autoCompleteInput = computed(() => searchStore.autoCompleteInput);
const autoCompleteQueries = computed(() => searchStore.autoCompleteQueries);
const autoCompleteCategories = computed(
  () => searchStore.autoCompleteCategories
);

const showCommonSearches = computed(
  () =>
    searchQuery.value.length < 2 ||
    (!autoCompleteQueries.value.length && !autoCompleteCategories.value.length)
);

const triggerSearch = async () => {
  if (!searchQuery.value) return;
  const searchPath = localePath({
    name: 'shop-search',
    query: { q: searchQuery.value, sort: sort.value },
  });
  await navigateTo(searchPath);
  searchStore.setShowSearchSuggestions(false);
};

const cancelSearch = () => {
  searchQuery.value = '';
  searchStore.setShowSearchSuggestions(false);
};

const triggerAutoComplete = () => {
  if (searchQuery.value.length > 2) {
    searchStore.autoComplete(searchQuery.value);
  } else if (autoCompleteQueries.value.length) {
    searchStore.clearAutoComplete();
  }
};

const hasAutoCompleteQueries = computed(
  () => autoCompleteQueries.value?.length
);

const hasAutoCompleteCategories = computed(
  () => autoCompleteCategories.value?.length
);

const debounceInput = debounce(() => {
  if (autoCompleteInput !== searchQuery.value) {
    triggerAutoComplete();
  }
}, 100);

const inputKeyup = (event) => {
  const isEnter = event.key === 'Enter';

  if (isEnter) {
    // Assuming you have a ref named searchInput in your template
    searchInput.value.blur();
  }

  searchStore.setShowSearchSuggestions(!isEnter);

  debounceInput(searchQuery.value);
};

const inputFocus = () => {
  if (
    !searchStore.commonSearches?.[locale.value]?.length &&
    !searchStore.loadingCommonSearches
  ) {
    searchStore.getCommonSearches();
  }

  searchStore.setShowSearchSuggestions(true);
};

const highlightAutocomplete = (str, substr) => {
  const strRegExp = new RegExp(substr, 'gi');
  const match = str.match(strRegExp);
  if (match?.length) {
    return str.replace(
      strRegExp,
      `<span class="font-normal text-black/87">${match[0]}</span>`
    );
  }
  return str;
};

const clickedSearchSuggestion = (suggestion) => {
  searchStore.setShowSearchSuggestions(false);
  searchQuery.value = suggestion;
};

const hideSearchSuggestion = () => {
  searchStore.setShowSearchSuggestions(false);
};

const clearSearch = () => {
  searchQuery.value = '';
  searchInput.value.focus();
};

const handleClickOutside = (event) => {
  /* TODO: v-click-outside do not work because we have multple searchforms on same page
  this an crude workaround to make it work by checking if you clicked inside a form */

  let clickInsideAForm = false;
  let currentElement = event?.srcElement?.parentNode;
  while (currentElement) {
    if (currentElement?.nodeName === form?.value?.nodeName) {
      clickInsideAForm = true;
      return;
    }
    currentElement = currentElement?.parentNode;
  }

  if (showSearchSuggestions.value && !clickInsideAForm) {
    searchStore.setShowSearchSuggestions(false);
    if (!$viewport.isGreaterThan('sm')) {
      searchQuery.value = '';
    }
  }
};
</script>

<style lang="postcss">
input[type='search']::-webkit-search-cancel-button {
  display: none;
}

input[type='search'] {
  -moz-appearance: none;
  -webkit-appearance: none;
}
</style>
